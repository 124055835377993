import React, { useEffect, useState } from 'react'

export default function AdviceSlip() {
    const [advice, setAdvice] = useState('Hello Tips!')
    const [count, setCount] = useState(0)

    async function getAdvice() {
        const res = await fetch('https://api.adviceslip.com/advice')
        const data = await res.json()
        setAdvice(data.slip.advice)
        setCount(c => c + 1)
    }

    useEffect(function () {
        getAdvice()
    }, [])

    return (
        <div>
            <h1 className="font-serif text-4xl m-10">{advice}</h1>
            <button
                onClick={getAdvice}
                className="bg-blue-400 hover:bg-blue-500 text-slate-100 mx-5 px-3 py-2 rounded-lg capitalize"
            >
                Get advice
            </button>
            <Message count={count} />
        </div>
    )
}

function Message(props) {
    return (
        <p className="border-l-8 border-blue-400 px-5 py-4 mx-5 mt-10">
            You have read{' '}
            <strong className="text-red-500">{props.count}</strong> piece(s) of
            advice.
        </p>
    )
}
