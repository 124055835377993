export default function ServiceOpinionInput({
    choices,
    selectedValue,
    onChange,
    children,
}) {
    return (
        <div>
            <label htmlFor="serviceOpinion">{children}</label>
            <select
                name="serviceOpinion"
                id="serviceOpinion"
                onChange={onChange}
                value={selectedValue}
            >
                {choices.map((choice, index) => (
                    <option
                        value={choice.rate}
                        key={index}
                    >{`${choice.label} (${choice.rate}%)`}</option>
                ))}
            </select>
        </div>
    )
}
