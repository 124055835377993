import './PizzaMenu.css'

export default function PizzaMenu() {
    return (
        <div className="pizzaMenuRootContainer">
            <div className="pizzaMenuContainer">
                <Header />
                <Menu />
                <Footer />
            </div>
        </div>
    )
}

const pizzaData = [
    {
        name: 'Focaccia',
        ingredients: 'Bread with italian olive oil and rosemary',
        price: 6,
        photoName: 'pizzas/focaccia.jpg',
        soldOut: false,
    },
    {
        name: 'Pizza Margherita',
        ingredients: 'Tomato and mozarella',
        price: 10,
        photoName: 'pizzas/margherita.jpg',
        soldOut: false,
    },
    {
        name: 'Pizza Spinaci',
        ingredients: 'Tomato, mozarella, spinach, and ricotta cheese',
        price: 12,
        photoName: 'pizzas/spinaci.jpg',
        soldOut: false,
    },
    {
        name: 'Pizza Funghi',
        ingredients: 'Tomato, mozarella, mushrooms, and onion',
        price: 12,
        photoName: 'pizzas/funghi.jpg',
        soldOut: false,
    },
    {
        name: 'Pizza Salamino',
        ingredients: 'Tomato, mozarella, and pepperoni',
        price: 15,
        photoName: 'pizzas/salamino.jpg',
        soldOut: true,
    },
    {
        name: 'Pizza Prosciutto',
        ingredients: 'Tomato, mozarella, ham, aragula, and burrata cheese',
        price: 18,
        photoName: 'pizzas/prosciutto.jpg',
        soldOut: false,
    },
]

function Header() {
    // const style = { color: 'red', fontSize: '48px', textTransform: 'uppercase' }
    const style = {}

    return (
        <header className="header">
            <h1 style={style}>Fast React Pizza Co.</h1>
        </header>
    )
}

// alternative to function Menu() {}
const Menu = () => {
    const pizzas = pizzaData
    // const pizzas = []
    const numPizzas = pizzas.length

    return (
        <main className="menu">
            <h2>Our menu</h2>

            {numPizzas > 0 ? (
                <>
                    <p>
                        Authentic Italian cuisine. 6 creative dishes to choose
                        from. All from our stone oven, all organic, all
                        delicious.
                    </p>

                    <ul className="pizzas sm:grid sm:grid-cols-2 sm:gap-10">
                        {pizzas.map((pizza) => (
                            <Pizza pizzaObj={pizza} key={pizza.name} />
                        ))}
                    </ul>
                </>
            ) : (
                <p>
                    We're still working on our menu. Please come back later :)
                </p>
            )}
        </main>
    )
}

// immediate destructuring, so need to use props
function Pizza({ pizzaObj }) {
    const { soldOut, photoName, name, ingredients, price } = pizzaObj

    // if (soldOut) return null

    return (
        // 2 equivalent ways
        // <li className={'pizza ' + (soldOut ? 'sold-out' : '')}>
        <li className={`pizza py-10 ${soldOut ? 'sold-out' : ''}`}>
            <img src={photoName} alt={name} />
            <div>
                <h3>{name}</h3>
                <p>{ingredients}</p>
                <span>{soldOut ? 'SOLD OUT' : price}</span>
            </div>
        </li>
    )
}

function Footer() {
    const hour = new Date().getHours()
    const openHour = 12
    const closeHour = 22
    const isOpen = hour >= openHour && hour <= closeHour

    // Alert displayed twice when StrictMode is enabled,
    // because it renders all components twice
    // if (hour >= openHour && hour <= closeHour) alert("We're currently open!")
    // else alert("Sorry, we're closed.")

    // Without jsx:
    // return React.createElement('footer', null, "We're currently open!")

    // We would need to duplicate the footer element
    // if (!isOpen) {
    // return <p>CLOSED</p>
    // }

    return (
        <footer className="footer text-center">
            {isOpen ? (
                <Order closeHour={closeHour} openHour={openHour} />
            ) : (
                <p>
                    We're happy to welcome you between {openHour}:00 and{' '}
                    {closeHour}:00.
                </p>
            )}
        </footer>
    )
}

function Order({ closeHour, openHour }) {
    return (
        <div className="order">
            <p>
                We're open from {openHour}:00 to {closeHour}:00. Come visit us
                or order online.
            </p>
            <button className="btn">Order</button>
        </div>
    )
}
