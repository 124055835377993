import { useState } from 'react'
import { Button } from './Button'

export function FormAddFriend({ onAddFriend }) {
    const imageUrl = 'https://i.pravatar.cc/48'
    const [name, setName] = useState('')
    const [image, setImage] = useState(imageUrl)

    function handleSubmit(e) {
        e.preventDefault() // do not reload the page

        if (!name || !image) return

        const id = crypto.randomUUID()
        const newFriend = {
            name,
            image: `${image}?=${id}`,
            balance: 0,
            id,
        }

        onAddFriend(newFriend)

        setName('')
        setImage(imageUrl)
    }

    return (
        <form className="form-add-friend" onSubmit={handleSubmit}>
            <label>👫 Friend name</label>
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <label>🖼️ Image URL</label>
            <input
                type="text"
                value={image}
                onChange={(e) => setImage(e.target.value)}
            />

            <Button>Add</Button>
        </form>
    )
}
