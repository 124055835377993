import { useState } from 'react'

export default function ChallengeDateCounter() {
    const [count, setCount] = useState(0)
    const [step, setStep] = useState(1)

    const date = new Date()
    date.setDate(date.getDate() + count)

    function handleStepChange(e) {
        setStep(Number(e.target.value))
    }

    function handleCountChange(e) {
        setCount(Number(e.target.value))
    }

    function increaseCount() {
        setCount((count) => count + step)
    }

    function decreaseCount() {
        setCount((count) => count - step)
    }

    function handleReset() {
        setCount(0)
        setStep(1)
    }

    return (
        <div className="">
            <div className="flex flex-col items-center">
                <Step step={step} handleStepChange={handleStepChange} />
                <Counter
                    count={count}
                    handleCountChange={handleCountChange}
                    increaseCount={increaseCount}
                    decreaseCount={decreaseCount}
                />
                <Result count={count} date={date} />
                <ResetButton
                    count={count}
                    step={step}
                    handleReset={handleReset}
                />
            </div>
        </div>
    )
}

function Step({ step, handleStepChange }) {
    return (
        <div className="mt-4 flex justify-center">
            <input
                type="range"
                min="1"
                max="10"
                value={step}
                onChange={handleStepChange}
            />
            <span className="ml-2">Step: {step}</span>
        </div>
    )
}

function Counter({ count, handleCountChange, increaseCount, decreaseCount }) {
    function SignButton({ sign, onClickCallback }) {
        return (
            <button className="size-8 border-2" onClick={onClickCallback}>
                {sign}
            </button>
        )
    }

    function MinusButton({ onClickCallback }) {
        return <SignButton sign="-" onClickCallback={onClickCallback} />
    }

    function PlusButton({ onClickCallback }) {
        return <SignButton sign="+" onClickCallback={onClickCallback} />
    }

    return (
        <div className="mt-4">
            <MinusButton className="" onClickCallback={decreaseCount} />
            <input
                type="number"
                placeholder="Count..."
                value={count}
                onChange={handleCountChange}
                className="h-8 border-2 pl-2"
            />
            <PlusButton className="" onClickCallback={increaseCount} />
        </div>
    )
}

function Result({ count, date }) {
    return (
        <p className="mt-4 text-center">
            {count === 0
                ? 'Today is '
                : count > 0
                  ? `${count} days from today is `
                  : `${Math.abs(count)} days ago was `}
            {date.toLocaleDateString('en-US', {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            })}
        </p>
    )
}

function ResetButton({ count, step, handleReset }) {
    return (
        (count !== 0 || step !== 1) && (
            <div>
                <button className="mt-6 border-2 p-2" onClick={handleReset}>
                    Reset
                </button>
            </div>
        )
    )
}
