import { useState } from 'react'
import './steps.css'

const messages = [
    'Learn React ⚛️',
    'Apply for jobs 💼',
    'Invest your new income 🤑',
]

export default function Steps() {
    return (
        <div className="stepsRoot">
            <StepsBlock />
            <StepsBlock />
            <StepsBlock />
        </div>
    )
}

function StepsBlock() {
    // hooks like useState can only be called on the top level of a function
    const [step, setStep] = useState(1)
    const [isOpen, setIsOpen] = useState(true)

    function handlePrevious() {
        // It is best practice and safer to use argument from setXXX callback
        // than directly use the state property
        if (step > 1) setStep((step) => step - 1)
    }

    function handleNext() {
        if (step < messages.length) setStep((step) => step + 1)
    }

    return (
        <div className="mx-auto max-w-screen-sm">
            <button
                className="close"
                onClick={() => setIsOpen((isOpen) => !isOpen)}
            >
                {isOpen ? '\u00D7' : '\u2193'}
            </button>

            {isOpen && (
                <div className="steps">
                    <div className="numbers">
                        <div className={step >= 1 ? 'active' : ''}>1</div>
                        <div className={step >= 2 ? 'active' : ''}>2</div>
                        <div className={step >= 3 ? 'active' : ''}>3</div>
                    </div>

                    <StepMessage step={step}>{messages[step - 1]}</StepMessage>

                    <div className="buttons">
                        <Button
                            textColor="#fff"
                            bgColor="#7950f2"
                            onClick={handlePrevious}
                        >
                            <span>👈</span> Previous
                        </Button>
                        <Button
                            textColor="#fff"
                            bgColor="#7950f2"
                            onClick={handleNext}
                        >
                            Next <span>👉</span>
                        </Button>
                    </div>
                </div>
            )}

            <hr />
        </div>
    )
}

function StepMessage({ step, children }) {
    return (
        <div className="message">
            <h3>Step {step}</h3>
            {children}
        </div>
    )
}

function Button({ textColor, bgColor, onClick, children }) {
    return (
        <button
            style={{
                backgroundColor: bgColor,
                color: textColor,
            }}
            onClick={onClick}
        >
            {children}
        </button>
    )
}
