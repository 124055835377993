import './profilecard.css'

const skills = [
    {
        skill: 'Android',
        level: 'advanced',
        color: '#a4c639',
    },
    {
        skill: 'iOS',
        level: 'advanced',
        color: 'lightgray',
    },
    {
        skill: 'Java',
        level: 'master',
        color: '#ee9900',
    },
    {
        skill: 'Kotlin',
        level: 'advanced',
        color: '#cc77ee',
    },
    {
        skill: 'Swift',
        level: 'advanced',
        color: '#ff6600',
    },
    {
        skill: 'JavaScript',
        level: 'intermediate',
        color: '#eeee00',
    },
    {
        skill: 'React',
        level: 'student',
        color: '#00bbbb',
    },
    {
        skill: 'NestJS',
        level: 'beginner',
        color: '#ee3333',
    },
]

function ProfileCard() {
    return (
        <div className="card font-mono">
            <Avatar />
            <p className="flags">🇧🇪 🇵🇱 🇺🇦</p>
            <div className="data">
                <Intro />
                <SkillList />
            </div>
        </div>
    )
}

function Avatar() {
    return (
        <img src="neodidcartoon.png" alt="Didier Delhaye" className="avatar" />
    )
}

function Intro() {
    return (
        <div className="profileCardRoot">
            <h1 className="font-serif text-3xl">Didier Delhaye</h1>
            <p>
                <strong>T</strong>
                <em>enacious</em> <strong>M</strong>
                <em>ultitasking</em> <strong>A</strong>
                <em>dventurer</em> developer at Neopixl.
            </p>
            <p className="mt-4">
                Was supposed to develop native mobile applications to enhance
                the skills learned at Technifutur, but was co-opted by the{' '}
                <strong>T</strong>
                <em>echnical</em> <strong>M</strong>
                <em>agic</em> <strong>A</strong>
                <em>rtisans</em> team to do all kinds of weird stuff on
                antiquities.
            </p>
        </div>
    )
}

function SkillList() {
    return (
        <div className="skill-list">
            {skills.map((skill) => (
                <TechTag
                    name={skill.skill}
                    level={skill.level}
                    color={skill.color}
                    key={skill.skill}
                />
            ))}
        </div>
    )
}

function TechTag({ name, level, color }) {
    return (
        <div className="skill" style={{ backgroundColor: color }}>
            <span>{name}</span>
            <span>
                {level === 'beginner' && '👶🏻'}
                {level === 'student' && '👨🏻‍🎓'}
                {level === 'intermediate' && '👍🏻'}
                {level === 'advanced' && '👌🏻'}
                {level === 'master' && '💪🏻'}
            </span>
        </div>
    )
}

export default ProfileCard
