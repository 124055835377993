export default function ResetButton({ onReset }) {
    return (
        <button
            className="mt-6 self-end rounded-lg border border-indigo-400 p-4 text-indigo-400 hover:bg-indigo-800 hover:text-indigo-50 "
            onClick={onReset}
        >
            Reset
        </button>
    )
}
