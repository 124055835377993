import { useState } from 'react'
import './travellist.css'
import Form from './travellist/Form'
import Logo from './travellist/Logo'
import PackingList from './travellist/PackingList'
import Stats from './travellist/Stats'

export default function TravelList() {
    const [items, setItems] = useState([])

    function handleAddItems(newItem) {
        setItems((items) => [...items, newItem])
    }

    function handleDeleteItem(itemId) {
        setItems((items) => items.filter((item) => item.id !== itemId))
    }

    function handleClearItems() {
        const confirmed = window.confirm(
            'Are you sure you want to delete all items?'
        )

        if (confirmed) setItems([])
    }

    function handleToggleItem(itemId) {
        setItems((items) =>
            items.map((item) =>
                item.id === itemId ? { ...item, packed: !item.packed } : item
            )
        )
    }

    return (
        <div className="travelListRoot">
            <div className="travelListSubroot">
                <div className="app">
                    <Logo />
                    <Form onAddItems={handleAddItems} />
                    <PackingList
                        items={items}
                        onDeleteItem={handleDeleteItem}
                        onToggleItem={handleToggleItem}
                        onClearItems={handleClearItems}
                    />
                    <Stats items={items} />
                </div>
            </div>
        </div>
    )
}
