import { useState } from 'react'
import BillInput from './BillInput'
import ResetButton from './ResetButton'
import Result from './Result'
import ServiceOpinionInput from './ServiceOpinionInput'

export default function TipCalculator() {
    const [bill, setBill] = useState('')
    const [tipRate, setTipRate] = useState(0)
    const [tipRateFriend, setTipRateFriend] = useState(0)
    const numericBill = Number(bill)
    const tip = numericBill * ((tipRate + tipRateFriend) / 2 / 100)

    const choices = [
        {
            rate: 0,
            label: 'Dissatisfied',
        },
        {
            rate: 5,
            label: 'It was okay',
        },
        {
            rate: 10,
            label: 'It was good',
        },
        {
            rate: 20,
            label: 'Absolutely amazing!',
        },
    ]

    function handleChangeBill(e) {
        setBill(e.target.value)
    }

    function handleChangeYourOpinion(e) {
        setTipRate(Number(e.target.value))
    }

    function handleChangeFriendOpinion(e) {
        setTipRateFriend(Number(e.target.value))
    }

    function handleReset() {
        setBill('')
        setTipRate(0)
        setTipRateFriend(0)
    }

    return (
        <div className="tipCalculatorRoot flex flex-col gap-4 p-4">
            <BillInput bill={bill} onChange={handleChangeBill} />
            <ServiceOpinionInput
                choices={choices}
                selectedValue={tipRate}
                onChange={handleChangeYourOpinion}
            >
                How did you like the service?
            </ServiceOpinionInput>
            <ServiceOpinionInput
                choices={choices}
                selectedValue={tipRateFriend}
                onChange={handleChangeFriendOpinion}
            >
                How did your friend like the service?
            </ServiceOpinionInput>

            {numericBill > 0 && (
                <>
                    <Result billAmount={numericBill} tip={tip} />
                    <ResetButton onReset={handleReset} />
                </>
            )}
        </div>
    )
}
