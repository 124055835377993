export default function Item({ item, onDeleteItem, onToggleItem }) {
    return (
        <li>
            <label>
                <input
                    type="checkbox"
                    value={item.packed}
                    onChange={() => {
                        onToggleItem(item.id)
                    }}
                />
                <span className="custom-checkbox" />
                <span
                    className="ml-3"
                    style={
                        item.packed ? { textDecoration: 'line-through' } : {}
                    }
                >
                    {item.quantity} {item.description}
                </span>
            </label>
            <button className="" onClick={() => onDeleteItem(item.id)}>
                ❌
            </button>
        </li>
    )
}
