export default function BillInput({ bill, onChange }) {
    return (
        <div>
            <label htmlFor="billAmount">How much was the bill?</label>
            <input
                type="number"
                name="billAmount"
                id="billAmount"
                onChange={onChange}
                value={bill}
                className="px-2"
                placeholder="Bill value"
            />
        </div>
    )
}
