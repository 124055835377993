import { useState } from 'react'
import FriendsList from '../tipCalculator/FriendsList'
import { Button } from './Button'
import { FormSplitBill } from './FormSplitBill'
import { FormAddFriend } from './FromAddFriend'
import './eatnsplit.css'

export default function EatNSplit() {
    const initialFriends = [
        {
            id: 118836,
            name: 'Clark',
            image: 'https://i.pravatar.cc/48?u=118836',
            balance: -7,
        },
        {
            id: 933372,
            name: 'Sarah',
            image: 'https://i.pravatar.cc/48?u=933372',
            balance: 20,
        },
        {
            id: 499476,
            name: 'Anthony',
            image: 'https://i.pravatar.cc/48?u=499476',
            balance: 0,
        },
    ]

    const [friends, setFriends] = useState(initialFriends)
    const [showAddFriend, setShowAddFriend] = useState(false)
    const [selectedFriend, setSelectedFriend] = useState(null)

    function handleShowAddFriend() {
        setShowAddFriend((showAddFriend) => !showAddFriend)
        setSelectedFriend(null)
    }

    function handleAddFriend(friend) {
        setFriends((friends) => [...friends, friend])
        setShowAddFriend(false)
    }

    function handleSelection(friend) {
        setSelectedFriend((currentlySelected) =>
            currentlySelected?.id === friend.id ? null : friend
        )
        setShowAddFriend(false)
    }

    function handleSplitBill(value) {
        setFriends((friends) =>
            friends.map((friend) =>
                friend.id === selectedFriend.id
                    ? { ...friend, balance: friend.balance + value }
                    : friend
            )
        )

        setSelectedFriend(null)
    }

    return (
        <div className="eatnsplitRoot">
            <div className="app">
                <div className="sidebar">
                    <FriendsList
                        friends={friends}
                        selectedFriend={selectedFriend}
                        onSelection={handleSelection}
                    />

                    {showAddFriend && (
                        <FormAddFriend onAddFriend={handleAddFriend} />
                    )}

                    <Button
                        className="add-button"
                        onClick={handleShowAddFriend}
                    >
                        {showAddFriend ? 'Close' : 'Add friend'}
                    </Button>
                </div>

                {selectedFriend && (
                    <FormSplitBill
                        selectedFriend={selectedFriend}
                        onSplitBill={handleSplitBill}
                    />
                )}
            </div>
        </div>
    )
}
