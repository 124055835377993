import { useState } from 'react'
import './accordion.css'

export default function Accordion() {
    const faqs = [
        {
            title: 'Where are these chairs assembled?',
            text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium, quaerat temporibus quas dolore provident nisi ut aliquid ratione beatae sequi aspernatur veniam repellendus.',
        },
        {
            title: 'How long do I have to return my chair?',
            text: 'Pariatur recusandae dignissimos fuga voluptas unde optio nesciunt commodi beatae, explicabo natus.',
        },
        {
            title: 'Do you ship to countries outside the EU?',
            text: 'Excepturi velit laborum, perspiciatis nemo perferendis reiciendis aliquam possimus dolor sed! Dolore laborum ducimus veritatis facere molestias!',
        },
    ]

    return (
        <div className="accordionRoot">
            <AccordionRoot data={faqs} />
        </div>
    )
}

function AccordionRoot({ data }) {
    const [currentlyOpen, setCurrentlyOpen] = useState(null)

    return (
        <div className="accordion">
            {data.map((item, index) => {
                return (
                    <AccordionItem
                        currentlyOpen={currentlyOpen}
                        onOpen={setCurrentlyOpen}
                        num={index}
                        title={item.title}
                        key={item.title}
                    >
                        {item.text}
                    </AccordionItem>
                )
            })}

            <AccordionItem
                currentlyOpen={currentlyOpen}
                onOpen={setCurrentlyOpen}
                num={22}
                title="React"
                key="react"
            >
                <p>Allows React developers to:</p>
                <ul>
                    <li>Break up UI into components</li>
                    <li>Make components reusable</li>
                    <li>Place state efficiently</li>
                </ul>
            </AccordionItem>
        </div>
    )
}

function AccordionItem({ num, title, currentlyOpen, onOpen, children }) {
    const isOpen = num === currentlyOpen

    function handleToggle() {
        onOpen(isOpen ? null : num)
    }

    return (
        <div className={`item ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
            <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
            <p className="title">{title}</p>
            <p className="icon">{isOpen ? '-' : '+'}</p>

            {isOpen && <div className="content-box">{children}</div>}
        </div>
    )
}
