import React, { useState } from 'react'
import Accordion from './components/Accordion'
import AdviceSlip from './components/AdviceSlip'
import ChallengeDateCounter from './components/ChallengeDateCounter'
import { FlashCards } from './components/Flashcards'
import PizzaMenu from './components/PizzaMenu'
import ProfileCard from './components/ProfileCard'
import Steps from './components/Steps'
import TravelList from './components/TravelList'
import EatNSplit from './components/eatnsplit/EatNSplit'
import TipCalculator from './components/tipCalculator/TipCalculator'

const projects = {
    'advice-slip': {
        name: 'Advice Slip',
        component: <AdviceSlip />,
    },
    'pizza-menu': {
        name: 'Pizza Menu',
        component: <PizzaMenu />,
    },
    'profile-card': {
        name: 'Profile Card',
        component: <ProfileCard />,
    },
    steps: {
        name: 'Steps',
        component: <Steps />,
    },
    'challenge-date-counter': {
        name: 'Date Counter',
        component: <ChallengeDateCounter />,
    },
    travelList: {
        name: 'Travel List',
        component: <TravelList />,
    },
    flashcards: {
        name: 'Flashcards',
        component: <FlashCards />,
    },
    accordion: {
        name: 'Accordion',
        component: <Accordion />,
    },
    tipCalculator: {
        name: 'Tip Calculator',
        component: <TipCalculator />,
    },
    eatnsplit: {
        name: "Eat'n'split",
        component: <EatNSplit />,
    },
}

export default function App() {
    const [project, setProject] = useState(projects['advice-slip'])

    function switchProject(projectId) {
        setProject(() => projects[projectId])
    }

    return (
        <div className="grid h-screen grid-rows-[auto_1fr_auto]">
            <header className="border-b-2 border-lime-950 bg-lime-50 p-4 ps-8 font-serif text-2xl italic text-lime-950">
                Welcome to my den!
                <nav className="flex flex-1 flex-wrap gap-4 bg-lime-50 px-1 py-3 font-sans">
                    {Object.entries(projects).map(([key, value]) => (
                        <ProjectButton
                            projectId={key}
                            projectName={value.name}
                            isActive={value === project}
                            key={key}
                        />
                    ))}
                </nav>
            </header>

            <main className="overflow-scroll">{project.component}</main>

            <footer className="border-t-2 border-lime-950 bg-lime-50 py-4 text-center text-sm text-lime-950">
                © Didier Delhaye - {new Date().getFullYear()}
            </footer>
        </div>
    )

    function ProjectButton({ projectId, projectName, isActive }) {
        // console.log(`${projectName} is active: ${isActive}`)
        return (
            <button
                onClick={() => switchProject(projectId)}
                className={`mx-0.5 rounded-full border-2 border-solid border-lime-500 bg-lime-200 px-2 py-1 text-lg text-lime-950 transition-colors duration-300 hover:border-lime-800 hover:bg-lime-600 hover:text-lime-50 hover:shadow-lg hover:shadow-lime-700 focus:outline-none focus:ring focus:ring-lime-500 focus:ring-offset-1 ${
                    isActive ? 'shadow-lg shadow-lime-700' : ''
                }`}
            >
                {projectName}
            </button>
        )
    }
}
